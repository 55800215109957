import {
  Box,
  Button,
  Text,
  Heading,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  useDisclosure,
  FormControl,
  FormHelperText,
  useToast,
  Input,
  Image
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useCallback, useState } from 'react';
// import Login from '../../Layout/lo'
import GA from 'react-ga';
import ReactGA from 'react-ga4';
import Joi from 'joi-browser';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import roya from '../../assets/images/images/Roya.png';
import eh from '../../assets/images/images/eh.png';
import an from '../../assets/images/images/an.png';
import market from '../../assets/images/images/Market.png';
import times from '../../assets/images/images/times.png';
import fanpin5 from '../../assets/images/images/panfan11.png';
import fanpin6 from '../../assets/images/images/panfan12.png';
import UPSS08 from '../../assets/images/images/UPSS-08.png';
import UPSSlogo01 from '../../assets/images/images/UPSSlogo-01.png';


import newbinanceimage from '../../assets/images/images/newbinanceimage.png';
// import VidHome from '../../assets/images/images/VidHome.mp4';
// import GT5W from '../../assets/images/images/GT5W.mp4';
import GT5W from '../../assets/images/images/Intro.mp4';


// import VidHome from 'https://www.youtube.com/watch?v=2YQd9r3vDqo';

// https://www.youtube.com/watch?v=2YQd9r3vDqo
// import pdfFile from '../../assets/images/images/RGBAYMBot®TermSheetv115.05.2023.pdf';

import { ContactUs } from '../../services/ContactUs/ContactUs';
import { useHistory } from 'react-router-dom';
import { Welcome } from './../../ReusableComponent/WelcomeVideo/Welcome';
import lottie from 'lottie-web';
import { isMobile } from 'react-device-detect';
const parentTranslationPath = 'Home';
const translationPath = '';

export const Home = ({ HomeRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const container = useRef();
  let token = localStorage.getItem('token');
  const handleOpen = () => {
    if (!token || token === 'null') {
      onOpen();
    } else {
      window.location.href = '/admin/profile';
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (!token || token === 'null') onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/lf30new.json'),
    });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', paddingTop: '2px' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', paddingTop: '2px' }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: false,
    slidesToShow: 5,
  };
  var settings2 = {
    dots: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const download1test = (filename, url) => {
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.rel = 'noopener';

    link.click();
    link.remove();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  const downloadtest2 = async (filename, url) => {
    const chunks = [];
    const res = await fetch(url);
    const reader = res.body.getReader();
    const readChunk = ({ done, value: chunk }) => {
      if (done) {
        const blob = new Blob(chunks, {
          type: 'application/octet-stream; charset=utf-8',
        });
        download1test(filename, URL.createObjectURL(blob));
        return;
      }
      chunks.push(chunk);
      reader.read().then(readChunk);
    };
    reader.read().then(readChunk);
  };

  const lorm =
    'Lorem ipsum dolor sitamet, conse ctetuer adipiscing elit, sed diam ';
  const images = [
    { img: market, title: lorm },
    { img: an, title: lorm },
    { img: roya, title: lorm },
    { img: times, title: lorm },
    { img: eh, title: lorm },
    { img: fanpin5, title: lorm },
    { img: fanpin6, title: lorm },
  ];

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [state, setState] = useState({
    message: {
      name: '',
      email: '',
      subject: 'heroshot',
      phone_number: '',
      message: 'make a call',
    },
    error: {},
  });
  const schema = Joi.object({
    name: Joi.string().required().label('Name'),
    email: Joi.string().required().email().label('email'),
    phone_number: Joi.number().required().label('Mobile Number'),
    subject: Joi.string().required().label('subject'),
    message: Joi.string().required().label('Message'),
  });

  const validate = () => {
    const result = Joi.validate(state.message, schema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleContactUsSS = useCallback(
    async e => {
      e.preventDefault();
      const errors = validate();
      setState(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors && errors) {
        console.log('errors errors', errors);
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } else {
        setLoading(true);
        const response = await ContactUs(state.message);
        if (response && response.data && response.data.accessToken !== null) {
          GA.event({
            category: 'User',
            action: 'Contact us-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Contact us',
          });
          toast({
            title: t(`${translationPath}sent-successfully`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
          setState(prev => ({
            ...prev,
            message: {
              ...prev.message,
              name: '',
              email: '',
              subject: 'heroshot',
              phone_number: '',
              message: 'make a call',
            },
          }));
          onClose();
        } else {
          toast({
            title: t(`${translationPath}somthing-wrong`),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <div ref={HomeRef}>
      <Welcome isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Grid
        h={{ base: '72vh', lg: '72vh' }}
        templateColumns={{ md: '1fr', lg: '1fr' }}
        templateRows={{ md: '1fr auto', lg: '1fr' }}
      >
        <GridItem margin="auto">
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Stack alignItems="center">
              <video
                autoPlay
                loop
                muted
                style={{
                  position: 'absolute',
                  width: '100%',
                  left: '50%',
                  top: '38%',
                  height: isMobile ? '180%' : '110%',
                  objectFit: 'cover',
                  transform: 'translate(-50%,-48%)',
                  zIndex: '-1',
                }}
              >
                <source src={GT5W} type="video/mp4" />
              </video>

              <Box w="100%" textAlign="-webkit-center" mb="10" mt={'-10%'}>
                <Box m="auto" w={{ base: '100%' }} textAlign="-webkit-center">
                  <Box
                    justifyContent={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'center' : 'center',
                    }}
                    w="100%"
                    m="auto"
                    display={{ base: 'block' }}
                  >
                    {/* UPSS08 */}
                    <Image
                      objectFit="contain"
                      src={UPSSlogo01}
                      alt={'alt'}
                      backgroundSize="cover"
                      // h={'50%'}
                      // width="30%"
                      width={'30%'}
                      justifyContent="center"
                    />
                    <Box
                      width={{ base: '280px', sm: '280px' }}
                      m={{ base: 'auto', lg: '0' }}
                      style={{ justifyContent: 'center', textAlign: 'center' }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          textAlign: '-webkit-center',
                          // text-align: -webkit-center
                        }}
                      ></div>
                      {/* </Box> */}
                    </Box>
                    {isMobile ? (
                      <Text m="2px" color="white" fontWeight={'bold'}
                      fontSize={{ base: 'lg', sm: 'l', lg: '3xl', xl: '4xl' }}
                      
                      >
                        {t(`${translationPath}UniversalProtection`)}
                       
                      </Text>
                    ) : (
                      <Text
                        m="2px"
                        whiteSpace="nowrap"
                        fontWeight={'bold'}
                        color="white"
                        fontSize={{ base: 'lg', sm: 'l', lg: '3xl', xl: '4xl' }}
                      >
                        {/* {t(`${translationPath}newTextTitle`)} */}
                        {t(`${translationPath}UniversalProtection`)}

                        
                      </Text>
                    )}
                  </Box>
                </Box>
                {isMobile ? (
                  <Box
                    m="auto"
                    w={{ base: '70%' }}
                    textAlign={{ base: 'center', lg: 'center' }}
                  >
                    {/* <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      // fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                    {t(`${translationPath}newTextTitle`)}
                    </Text> */}
                    <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                      {t(`${translationPath}newTextText`)}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    m="auto"
                    w={{ base: '60%' }}
                    textAlign={{ base: 'center', lg: 'center' }}
                  >
                    <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                      {t(`${translationPath}newTextTitle`)}
                    </Text>
                    <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                      {t(`${translationPath}newTextText`)}
                    </Text>
                  </Box>
                )}

                <Box
                  m="auto"
                  w={{ base: '100%' }}
                  textAlign={{ base: 'center', lg: 'left' }}
                ></Box>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
