
import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';

export const ContactUs = async (body) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/core/user/rest/AYMBot/contactUs`,body,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  ,);
  return result;
};
