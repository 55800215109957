import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';

export const ActiveAccount = async ({ code, email }) => {
  const result = await HttpServices.get(
    `${config.serverAddress}/core/user/rest/auth/activeAccount?code=${code}&email=${email}`,{},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
  return result;
};
