import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Box,
    Text,
    Stack,
    SimpleGrid,
    GridItem,
    Grid,
    Avatar,
  } from '@chakra-ui/react';

  const parentTranslationPath = 'Clients';
const translationPath = '';

export const Cards = ({comments}) => {
  
  const { t } = useTranslation(parentTranslationPath);
    return (
        <Grid w='100%'>
        
        <GridItem h='auto' columns={1} my="8" display={{base:'block', xl:'none'}}>
        <SimpleGrid width="100%" textAlign="center">
       
          <Box>
            <Stack spacing={6} alignItems="center">
              <Box w='60%'>
                <Avatar
                color="white"
               size='2xl'
               boxShadow={comments.shadow}
               bg={comments.color}
               name={t(`${translationPath}${comments.name}`)}
                />
              </Box> 
            </Stack>

            <Box
              textAlign="center"
              width="80%"
              color="#757575"
              mb="10"
              w='100%'
              fontSize="18px"
            ></Box>
            <Stack spacing={6} alignItems="center">
              <Box w="90%" textAlign="center">
              <Text fontWeight="bold" fontSize="xl" color="#151C2F" w={{base: '60%', md: '70%%', xl:'100%' }} m="auto">
                  {t(`${translationPath}${comments.name}`)}
                </Text>
                <Text fontSize="sm" color="#757575" w={{base: '80%', md: '70%%', xl:'100%' }} m="auto">
                  {t(`${translationPath}${comments.text}`)}
                </Text>
              </Box>
            </Stack>
          </Box>
        </SimpleGrid>
      </GridItem>
        </Grid>
    )
}
