import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';

export const logIn = async ({ username, password }) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/core/user/rest/auth/login`,{},
    {
      headers: {
        'username': `${username}`,
        'password': `${password}`,
      },
    }
  , );
  return result;
};
