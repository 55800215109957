
import { HttpServices } from '../../../src/helper/HttpMethod.Helper';
import  config  from '../../assets/Configration/Configration.json'


export const signUp = async(body)=> {
    const result =await HttpServices.post(`${config.serverAddress}/core/user/rest/auth/signup`,body)
    return result;
};


