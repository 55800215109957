import React from 'react';
import { Modal, ModalContent } from '@chakra-ui/react';

import Login from './../../Layout/HomeLogin/Login';
export function Model({ onOpen, onClose, isOpen }) {
  return (
    <>
      <Modal
        className="border"
        borderRadius="md"
        size='auto'
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent width={{base: "350px",  md:"600px", lg: "950px"}}>
          <Login onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}
export default Model;
