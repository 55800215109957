import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  SimpleGrid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
// import Design2test2 from '../../assets/images/images/Design2test2.png';
import Design2test2 from '../../assets/images/images/IMG-logo-1V.jpeg';



import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
const parentTranslationPath = 'FAQ';
const translationPath = '';

export const FAQ = ({ FAQRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);

  const handleOpen = () => {
    window.open('https://www.aymtrack.com/', '_blank');
  };

  return (
    <div className="FAQ" ref={FAQRef} id="FAQs">
      <Grid
        templateColumns={{ md: '1fr', lg: '1fr ' }}
        templateRows={{ md: '1fr auto', lg: '1fr' }}
        bgGradient={'linear(to-r,#192238, #000000)'}
        height={'500px'}
      >
        <GridItem margin="auto">
          <SimpleGrid
            spacing={10}
            display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
          >
            <Stack spacing={0} alignItems="center">
              <Box
                ml={'5%'}
                style={{ marginTop: isMobile ? '5%' : null }}
                h="auto"
                borderRadius="20px"
              >
                <div style={{ width: '100%' ,textAlign:'-webkit-center' ,marginTop: '2%'}}>
                  <img
                    style={{
                      height: '80%',
                      width: isMobile ? '90%' : '50%',
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                    className="shadowforimage"
                    alt="Dan Abramov"
                    src={Design2test2}
                  />
                </div>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>

        {/* <GridItem margin="auto">
          <SimpleGrid
            display={'center'}
            columns={{ sm: 1, md: 1, xl: 1 }}
            spacing={10}
            my="26px"
          >
            <Stack spacing={6} alignItems="center">
              <Box w="100%" textAlign="-webkit-center" my="15">
                <Box
                  display="flex "
                  textAlign={{ base: 'center', lg: 'center' }}
                  justifyContent="center"
                  style={{
                    direction: lang.currentLanguage === 'ar' ? 'rtl' : '',
                  }}
                >
                  <Text
                    color="white"
                    fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: '2xl' }}
                    // my={2}
                  >
                    {t(`${translationPath}Asked`)}
                  </Text>
                  <Text
                    mx="2"
                    color="#4FD1C5"
                    fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: '2xl' }}
                    // my={2}
                  >
                    {t(`${translationPath}Asked-2`)}
                  </Text>
                </Box>
                {lang.currentLanguage === 'en' ? (
                  <Text
                    color="white"
                    // fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: 'xl' }}
                    my={2}
                    mx={2}
                  >
                    {t(`${translationPath}Asked2`)}
                  </Text>
                ) : (
                  <Box style={{ direction: 'rtl' }}>
                    <Text
                      color="white"
                      // fontWeight="bold"
                      fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: 'xl' }}
                      my={2}
                      mx={2}
                    >
                      {t(`${translationPath}Asked2`)}
                    </Text>
                  </Box>
                )}

                <Button
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  mt={isMobile ? '20px' : '10px'}
                  fontSize="15px"
                  bg="#4FD1C5"
                  color="white"
                  size="md"
                  w="160px"
                  onClick={handleOpen}
                >
                  {t(`${translationPath}view`)}
                </Button>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem> */}
      </Grid>
    </div>
  );
};
