import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';

export const forgotPassword = async ( {email}) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/core/user/rest/auth/forgetpassword`,{},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'username': `${email}`,
      },
    }
  ,);
  return result;
};
