import React, { useRef } from 'react';


import { Header } from '../Layout/Header';
import { MainHome } from '../Layout/MainHome';
import { Footer } from '../Layout/Footer/Footer';

function Main() {
  const HomeRef = useRef();
  const WhatIsAYMBotRef = useRef();
  const WhyAYMBotRef = useRef();
  const WhowItWorkRef=useRef();
  const GetStartedRef = useRef();
  const PricingRef=useRef();
  const ContactUsRef=useRef();
  const AYMBotClientsRef = useRef();
  const FAQRef = useRef();
  const DownloadReportRef = useRef();

  
  const AffiliateRef = useRef();

  const executeScroll = Ref =>
    Ref &&
    Ref.current &&
    window.scrollTo(  {
      top: Ref.current.offsetTop-60,
      left: 0,
      behavior: 'smooth'
    })   
    const executeMobScroll = Ref =>
    Ref &&
    Ref.current &&
    window.scrollTo(  {
      top: Ref.current.offsetTop-600,
      left: 0,
      behavior: 'smooth'
    })  
  return (
<div>
      <Header  
        HomeRef={HomeRef}
        WhatIsAYMBotRef={WhatIsAYMBotRef}
        WhyAYMBotRef={WhyAYMBotRef}
        WhowItWorkRef={WhowItWorkRef}
        GetStartedRef={GetStartedRef}
        PricingRef={PricingRef}
        ContactUsRef={ContactUsRef}
        AYMBotClientsRef={AYMBotClientsRef}
        FAQRef={FAQRef}
        DownloadReportRef={DownloadReportRef}
        AffiliateRef={AffiliateRef}
        executeScroll={executeScroll}
        executeMobScroll={executeMobScroll}
      />
      <MainHome
        HomeRef={HomeRef}
        WhatIsAYMBotRef={WhatIsAYMBotRef}
        WhowItWorkRef={WhowItWorkRef}
        WhyAYMBotRef={WhyAYMBotRef}
        GetStartedRef={GetStartedRef}
        PricingRef={PricingRef}
        ContactUsRef={ContactUsRef}
        AYMBotClientsRef={AYMBotClientsRef}
        FAQRef={FAQRef}
        DownloadReportRef={DownloadReportRef}
        AffiliateRef={AffiliateRef}
      />
      <Footer />
      </div>
    
  );
}

export default Main;
