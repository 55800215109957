import React, { useRef } from 'react';
import {
  Grid,
  Text,
  GridItem,
  SimpleGrid,
  Box,
  Image,
  Flex,
  Center,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@chakra-ui/media-query';




import IMG01 from './../../assets/images/whyaym/IM/IMG01.jpg';
import IMG001 from './../../assets/images/whyaym/IM/IMG001.jpg';

import IMG02 from './../../assets/images/whyaym/IM/IMG02.jpg';
import IMG002 from './../../assets/images/whyaym/IM/IMG002.jpg';

import IMG03 from './../../assets/images/whyaym/IM/IMG03.jpg';
import IMG003 from './../../assets/images/whyaym/IM/IMG003.jpg';

import IMG04 from './../../assets/images/whyaym/IM/IMG04.jpg';
import IMG004 from './../../assets/images/whyaym/IM/IMG004.jpg';

import IMG05 from './../../assets/images/whyaym/IM/IMG05.jpg';
import IMG005 from './../../assets/images/whyaym/IM/IMG005.jpg';

import IMG06 from './../../assets/images/whyaym/IM/IMG06.jpg';
import IMG006 from './../../assets/images/whyaym/IM/IMG006.jpg';






import { isMobile } from 'react-device-detect';

const parentTranslationPath = 'WhyAYM';
const translationPath = '';

const ImageToggleOnMouseOver = ({ primaryImg, secondaryImg }) => {
  const imageRef = useRef(null);


  return (
    <Image
    objectFit="cover"
    // src={item.img}
    alt={'alt'}
    backgroundSize="cover"
    // h="400px"
    src={primaryImg}
    // width="30%"
    width={'100%'}
    onMouseOver={() => {
      imageRef.current.src = secondaryImg;
    }}
    onMouseOut={() => {
      imageRef.current.src = primaryImg;
    }}
    ref={imageRef}

  />
    
  );
};

export const WhyAYMBot = ({ WhyAYMBotRef }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const [isSmallScreenFold] = useMediaQuery(
    '(min-width: 332px)' && '(max-width: 400px)'
  );
  // const [isSmallScreenFold] = useMediaQuery('(min-width: 332px)');
  let lang = JSON.parse(localStorage.getItem('localization'));
  // const { t } = useTranslation(parentTranslationPath);

  const { t } = useTranslation(parentTranslationPath);
  const images = [
    {
      name: 'Intelligent',
      img: IMG01,
      img2: IMG001,
      title: 'Intelligent-title',
    },
    {
      name: 'Bleeding-Edge',
      img: IMG02,
      img2: IMG002,
      title: 'Bleeding-Edge-title',
    },
    {
      name: 'Fully-Automated',
      img: IMG03,
      img2: IMG003,
      title: 'Fully-Automated-title',
    },
    {
      name: 'Safe-Flexible',
      img: IMG04,
      img2: IMG004,
      title: 'Safe-Flexible=title',
    },
    {
      name: 'Relentless',
      img: IMG05,
      img2: IMG005,
      title: 'Relentless-title',
    },

    {
      name: 'Data-Driven',
      img: IMG06,
      img2: IMG006,
      title: 'Data-Driven-title',
    },
    // {
    //   name: 'Reliable',
    //   img: IMG11,
    //   title: 'Reliable-title',
    // },
    // {
    //   name: 'Inhuman-Returns',
    //   img: InhumanReturns,
    //   title: 'Inhuman-Returns-title',
    // },
    // {
    //   name: 'Investing-made-simple',
    //   img: InvestingMadeSimple,
    //   title: 'Investing-made-simple-title',
    // },
    // {
    //   name: 'Generate-passive-income',
    //   img: GeneratePassiveIncome,
    //   title: 'Generate-passive-income-title',
    // },
    // {
    //   name: 'Limite-Downside',
    //   img: LimitedDownside,
    //   title: 'Limite-Downside-title',
    // },
    // {
    //   name: 'AYMTrack',
    //   img: AYMTrack,
    //   title: 'AYMTrack-title',
    // },
  ];

  return (
    <div ref={WhyAYMBotRef} id="WhyAYMBot">
      <Box mt="8" mb={8} bgColor="#FFF" justifyItems="center">
        <Center>
          <Box
            display="flex"
            justifyItems="center"
            alignItems="center"
            alignSelf="center"
          >
            <Text color="#4FD1C5" fontSize="40px" mt="8" fontWeight="bold">
              {' '}
              {t(`${translationPath}why-aym`)}
            </Text>
          </Box>
        </Center>

        <SimpleGrid
          display="flex"
          mt="8"
          bgColor="#FFF"
          justifyItems="center"
          justifyContent={'center'}
        >
          <div>
            <Box>
              <Box width="100%" m="auto">
                {isSmallScreen || isSmallScreenFold ? (
                  <Grid
                    bgColor="#FFF"
                    justifyItems="center"
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(2, 1fr)"
                    gap={2}
                    mt="8"
                  >
                    {images.map((item, i) => (
                      <GridItem key={i}>
                        <SimpleGrid minChildWidth="120px">
                          <Box width="80%" m="auto">
                            <Flex
                              display="block"
                              textAlign={{ base: 'center' }}
                            >
                              <Box display="flex" justifyContent="center">
                                {' '}
                                <Image
                                  objectFit="contain"
                                  src={item.img}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  h="80px"
                                  // width="30%"
                                  width={['100%', '50%']}
                                />
                              </Box>

                              <Text
                                fontSize="md"
                                color="#4FD1C5"
                                mt="2"
                                display="flex"
                                justifyContent="center"
                                fontWeight="bold"
                              >
                                {t(`${translationPath}${item.name}`)}
                              </Text>
                            </Flex>
                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              width="100%"
                              color="#FFF"
                              mb="10"
                              fontSize="18px"
                            >
                              <div
                                style={{ width: '90%', display: 'inline-flex' }}
                              >
                                <Text
                                  fontSize="sm"
                                  m={{ sm: 'auto' }}
                                  textAlign={{ base: 'center' }}
                                  w={{ sm: '60%', md: '70%', lg: '100%' }}
                                >
                                  {t(`${translationPath}${item.title}`)}
                                </Text>
                              </div>
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    bgColor="#FFF"
                    justifyItems="center"
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(3, 1fr)"
                    gap={2}
                    mt="8"
                  >
                    {images.map((item, i) => (
                      <GridItem key={i}>
                        <SimpleGrid minChildWidth="120px">
                          <Box width="80%" m="auto">
                            <Flex
                              display="block"
                              textAlign={{ base: 'center' }}
                            >
                              <Box display="flex" justifyContent="center">
                                {' '}
                              
                                <ImageToggleOnMouseOver
                                  primaryImg={item.img}
                                  secondaryImg={item.img2}
                                  alt=""
                                />
                              </Box>

                              <Text
                                fontSize="md"
                                color="#4FD1C5"
                                mt="2"
                                display="flex"
                                justifyContent="center"
                                fontWeight="bold"
                              >
                                {t(`${translationPath}${item.name}`)}
                              </Text>
                            </Flex>
                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              width="100%"
                              color="#757575"
                              mb="10"
                              fontSize="18px"
                            >
                              <div
                                style={{ width: '90%', display: 'inline-flex' }}
                              >
                                <Text
                                  fontSize="sm"
                                  m={{ sm: 'auto' }}
                                  textAlign={{ base: 'center' }}
                                  w={{ sm: '60%', md: '70%', lg: '100%' }}
                                >
                                  {t(`${translationPath}${item.title}`)}
                                </Text>
                              </div>
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
                  </Grid>
                )}
              </Box>
            </Box>
          </div>
        </SimpleGrid>
      </Box>
    </div>
  );
};
