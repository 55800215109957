import React, { useState, useCallback, useEffect } from 'react';
import { AYMBotResults } from '../../Components/AYMBotResults/AYMBotResults';
import {
  Box,
  Text,
  Grid,
  VStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LineChart from './../Charts/LineChart';
// import AYM from '../../assets/images/images/AYM.png';
import { Model } from './../Model/HomeModel';
import { GetPricing } from '../../services/Pricing';
import { App } from '../Swiper/SwiperCard.jsx';

import step1 from '../../assets/images/images/step-1.png';
import step2 from '../../assets/images/images/step-2.png';
import step3 from '../../assets/images/images/step-3.png';

import IMG9 from './../../assets/images/whyaym/IMG-9.jpg';
import IMG2 from './../../assets/images/whyaym/IMG-2.png';
import IMG7 from './../../assets/images/whyaym/IMG-7.jpg';
import IMG8 from './../../assets/images/whyaym/IMG-8.png';
import IMG11 from './../../assets/images/whyaym/IMG-11.jpg';
import IMG14 from './../../assets/images/whyaym/IMG-14.png';
import IMG17 from './../../assets/images/whyaym/IMG-17.png';





const parentTranslationPath = 'Pricing';
const translationPath = '';

export const Pricing = ({ PricingRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sliderValue, setSliderValue] = useState(500);
  const [chartData, setChartData] = useState([]);
  const [fees, setFees] = useState(199);
  const [plan, setPlan] = useState('Free');
  const getBalance = useCallback(async () => {
    const response = await GetPricing(sliderValue);
    if (response && response.data.status === 'success') {
      setFees(response.data.result.fee);
      setPlan(response.data.result.plan);
      setChartData([response.data.result]);
    } else {
      setPlan('Free');
      chartData([]);
      setFees(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  const format = val => `$` + val;

  useEffect(() => {
    const timeoutId = setTimeout(() => getBalance(), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getBalance]);

  const handleOpen = () => {
    if (lang.currentLanguage === 'en') {
      window.open(
        'https://blog.aymbot.com/en/tiered-pricing-explained/',
        '_blank'
      );
    } else {
      window.open(
        'https://blog.aymbot.com/ar/%d8%b4%d8%b1%d8%a7%d8%a6%d8%ad-%d8%a5%d8%b4%d8%aa%d8%b1%d8%a7%d9%83%d8%a7%d8%aa-aymbot/',
        '_blank'
      );
    }
  };

  const images = [
    {
      name: 'Intelligent',
      img: IMG9,
      title: 'Intelligent-title',
    },
    {
      name: 'Bleeding-Edge',
      img: IMG2,
      title: 'Bleeding-Edge-title',
    },
    {
      name: 'Fully-Automated',
      img: IMG14,
      title: 'Fully-Automated-title',
    },
    {
      name: 'Safe-Flexible',
      img: IMG17,
      title: 'Safe-Flexible=title',
    },
    {
      name: 'Relentless',
      img: IMG7,
      title: 'Relentless-title',
    },

,
    
  ];



  const { t } = useTranslation(parentTranslationPath);
  return (
    <div ref={PricingRef} id="Pricing">
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} sixe="xl" />
      <Grid h="auto">
        <Box bgGradient="linear(to-r,#192238, #000000)">
          <Box m="auto">
            {' '}
            <Text
              textAlign="center"
              mt="6"
              mb="12"
              color="white"
              fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              fontWeight="bold"
            >
              {t(`${translationPath}PRICING-PLANS`)}
            </Text>
          </Box>

          <Box width="100%" h="650px" m="auto">
            <App />
          </Box>

         
        </Box>
      </Grid>
      {/* <AYMBotResults /> */}
    </div>
  );
};
