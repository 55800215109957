import React from 'react';
import { Home } from '../../Components/Home';
import { WhatIsAYMBot } from '../../Components/WhatIsAYMBot';
import { WhyAYMBot } from '../../Components/WhyAYMBot';
import { GetStarted } from '../../Components/GetStarted';
import { AYMBotClients } from '../../Components/AYMBotClients/AYMBotClients';
import { FAQ } from '../../Components/FAQ/FAQ';
// import { DownloadReport } from '../../Components/DownloadReport/DownloadReport';
import { Affiliate } from '../../Components/Affiliate';
import { Pricing } from '../../Components/Pricing';
import { ContactUs } from './../../Components/ContactUsSection/ContactUs';
import { HowItWork } from '../../Components/HowItWork';

export const MainHome = ({
  HomeRef,
  WhatIsAYMBotRef,
  WhyAYMBotRef,
  GetStartedRef,
  PricingRef,
  ContactUsRef,
  AYMBotClientsRef,
  FAQRef,
  WhowItWorkRef,
  AffiliateRef,
  
}) => {
  return (
    <div>
      <Home HomeRef={HomeRef} />
      <WhyAYMBot WhyAYMBotRef={WhyAYMBotRef} />
      <WhatIsAYMBot WhatIsAYMBotRef={WhatIsAYMBotRef} />
      <HowItWork WhowItWorkRef={WhowItWorkRef}/>
      <GetStarted GetStartedRef={GetStartedRef} />
      <Pricing PricingRef={PricingRef}/>
      <AYMBotClients AYMBotClientsRef={AYMBotClientsRef} />
      {/* <FAQ FAQRef={FAQRef} /> */}
      <Affiliate AffiliateRef={AffiliateRef} />
      
      {/* <DownloadReport DownloadReportRef={DownloadReportRef} /> */}
      <ContactUs ContactUsRef={ContactUsRef}  />
    </div>
  );
};
