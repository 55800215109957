import React, { useState, useCallback, useEffect } from 'react';
import {
  Flex,
  Heading,
  Input,
  Select,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Grid,
  Text,
  Checkbox,
  SimpleGrid,
  GridItem,
  FormControl,
  FormHelperText,
  useToast,
  InputRightElement,
  Image,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import Country from '../../assets/Json/countries.json';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import LoginMob from '../../assets/images/images/loginMob.png';
import { CloseIcon, EmailIcon } from '@chakra-ui/icons';
import { signUp } from '../../services/signUp';
import { logIn } from '../../services/logIn';
import { forgotPassword } from '../../services/forgotPassword';
import { ResetPassword } from '../../services/resetPassword';
import Joi from 'joi-browser';
import { languageChange } from '../../helper/TranslateMethod.Helper';
import GA from 'react-ga';
import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import OTPImg from '../../assets/images/images/OTP.png';
import { auth } from '../../firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { FaShieldAlt } from 'react-icons/fa';
import Design2test2 from '../../assets/images/images/Design2test2.png';
import UPSSPrime from '../../assets/images/images/UPSSPrime.jpeg';
import UPSS08 from '../../assets/images/images/UPSS-08.png';
import UPSS01 from '../../assets/images/images/UPSSlogo-01.png';
import VINI from '../../assets/images/images/VINI.png';

import { useHistory } from "react-router-dom";



// UPSSPrime.jpeg
// import './styledVerify.css';

import OtpInput from 'otp-input-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { FAQ } from '../../Components/FAQ/FAQ';
// imoprt Faq

// import { FAQ } from '../../services/ContactUs/ContactUs';
// imoprt {FAQ}

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const parentTranslationPath = 'Welcome';
const translationPath = '';
const StartPage = ({ onClose }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [loading, setLoading] = useState(false);
  let lang = JSON.parse(localStorage.getItem('localization')) || 'en';
  const isLarger = window.matchMedia('(max-width: 62em)');

  const handlelanguage = () => {
    if (lang.currentLanguage === 'ar') {
      languageChange('en');
    } else {
      languageChange('ar');
    }
  };
  const history = useHistory();
  
  const coursesPage = () => {
      history.push("/FAQ")
  }

  return (
    <Box  bg="white">
    {/* <Box bg="black" > */}
      <Button
        // bg="black"
        color="black"
        leftIcon={<CloseIcon />}
        // mt = {}
        onClick={onClose}
        variant="ghost"
      ></Button>
      {/* </Box> */}
      <Center
        style={{ marginTop: isMobile ? '5%' : null }}
        h="auto"
        borderRadius="20px"
      >
        <div style={{ width: '20%' }}>
          <img
            style={{
              height: '20%',
              width: isMobile ? '80%' : '100%',
              textAlign: 'center',
              alignSelf: 'center',
            }}
            className="shadowforimage"
            alt="Dan Abramov"
            src={UPSSPrime}
          />
        </div>
       

      </Center>
      <Center>
      {/* <Text color ="#221E55">  </Text> */}
        <Text color ="#221E55" className={isMobile?"UpssheadpopupPhone":"Upssheadpopup"}> Universal Protection for Smart System </Text>

      </Center>
      <Center>
      {/* <Text color ="#221E55">  </Text> */}
      <Text color ="#221E55" className={isMobile?"UpssheadpopupPhone":"Upssheadpopup"}> الحماية العالمية للانظمة الذكية </Text>

      </Center>

      
      <Box>
     
      </Box>
      <Grid
        // templateColumns={{ md: '1fr', lg: '1fr 1fr 1fr' }}
        templateColumns={{ md: '1fr', lg: '1fr  ' }}

        templateRows={{ md: '1fr auto', lg: '1fr' }}
        bgGradient={'linear(to-r,#fff, #fff)'}
        height={'400px'}
      >
      
       
        <GridItem margin="auto">
          <SimpleGrid
            // spacing={10}
            display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
          >
            <Stack spacing={0} alignItems="center">
              <Box
              className='weCenterImages'
                // ml={'10%'}
                // style={{ marginTop: isMobile ? '2%' : null }}
                h="auto"
              >
                <div style={{ width: '100%' }}>
                  <img
                    style={{
                      height: '30%',
                      width: isMobile ? '80%' : '40%',
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                    className="shadowforimage"
                    alt="Dan Abramov"
                    src={UPSS01}
                  />
                </div>
                <Box className='weCenter' style={{color:'#151C2F'}}>
                <Text>Welcome to our E-commerce Website </Text>
                <Button
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  mt={isMobile ? '20px' : '10px'}
                  fontSize="15px"
                  bg="#4FD1C5"
                  color="white"
                  size="md"
                  w="160px"
                
              
                  // onClick={coursesPage}
                >
                  {t(`${translationPath}view`)}
                </Button>
                </Box>
              
              </Box>
              
            </Stack>
            
          </SimpleGrid>
        </GridItem>



        {/* <GridItem margin="auto">
          <SimpleGrid
            spacing={10}
            display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
          >
            <Stack spacing={0} alignItems="center">
              <Box
              className='weCenterImages'
                ml={'10%'}
                style={{ marginTop: isMobile ? '5%' : null }}
                h="auto"
              >
                <div style={{ width: '100%' }}>
                  <img
                    style={{
                      height: '60%',
                      width: isMobile ? '90%' : '50%',
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                    className="shadowforimage"
                    alt="Dan Abramov"
                    src={VINI}
                  />
                </div>
                <Box className='weCenter'>
                <Text> Welcome Vini Offers </Text>
                <Button
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  mt={isMobile ? '20px' : '10px'}
                  fontSize="15px"
                  bg="#4FD1C5"
                  color="white"
                  size="md"
                  w="160px"
                  // onClick={handleOpen}
                  onClick={coursesPage}
                >
                  {t(`${translationPath}view`)}
                </Button>
                </Box>
              
              </Box>
              
            </Stack>
            
          </SimpleGrid>
        </GridItem> */}

     
        {/* <GridItem margin="auto">
          <SimpleGrid
            display={'center'}
            columns={{ sm: 1, md: 1, xl: 1 }}
            spacing={10}
            my="26px"
          >
            <Stack spacing={6} alignItems="center">
              <Box w="100%" textAlign="-webkit-center" my="15">
                <Box
                  display="flex "
                  textAlign={{ base: 'center', lg: 'center' }}
                  justifyContent="center"
                  style={{
                    direction: lang.currentLanguage === 'ar' ? 'rtl' : '',
                  }}
                >
                  <Text
                    color="white"
                    fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: '2xl' }}
                    // my={2}
                  >
                    {t(`${translationPath}Asked`)}
                  </Text>
                  <Text
                    mx="2"
                    color="#4FD1C5"
                    fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: '2xl' }}
                    // my={2}
                  >
                    {t(`${translationPath}Asked-2`)}
                  </Text>
                </Box>
                {lang.currentLanguage === 'en' ? (
                  <Text
                    color="white"
                    // fontWeight="bold"
                    fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: 'xl' }}
                    my={2}
                    mx={2}
                  >
                    {t(`${translationPath}Asked2`)}
                  </Text>
                ) : (
                  <Box style={{ direction: 'rtl' }}>
                    <Text
                      color="white"
                      // fontWeight="bold"
                      fontSize={{ base: 'md', sm: 'md', md: 'xl', xl: 'xl' }}
                      my={2}
                      mx={2}
                    >
                      {t(`${translationPath}Asked2`)}
                    </Text>
                  </Box>
                )}

                <Button
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  mt={isMobile ? '20px' : '10px'}
                  fontSize="15px"
                  bg="#4FD1C5"
                  color="white"
                  size="md"
                  w="160px"
                  // onClick={handleOpen}
                >
                  {t(`${translationPath}view`)}
                </Button>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem> */}
      </Grid>
    </Box>
  );
};

export default StartPage;
