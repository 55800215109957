import { Box, Grid, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { GetParams } from '../../helper/GetParam';
import lottie from 'lottie-web';
import  config  from '../../assets/Configration/Configration.json'
import { ActiveAccount } from '../../services/ActiveUserAccount/ActiveAccount';
export const Activation = () => {
 let lang = JSON.parse(localStorage.getItem('localization'));
  const [isLoading, setIsLoading] = useState(false);
  const [activeMessage, SetActiveMessage] = useState();
  const container = useRef();
  const [params, setParams] = useState({
    code: GetParams('code'),
    email: GetParams('email'),
  });

  useEffect(() => {
    setParams(() => ({
      code: GetParams('code'),
      email: GetParams('email'),
    }));
  }, []);

  
  const ActiveUserAccount =(async () => {
    setIsLoading(true);
    const response = await ActiveAccount(params);
    if (response) {
      setIsLoading(false);
      SetActiveMessage(response.data);
      if(response.data==='Account activated successfully!' && lang.currentLanguage=== 'en')
      SetActiveMessage('Account activated successfully!');
      else if(response.data==='Account activated successfully!' && lang.currentLanguage=== 'ar'){
        SetActiveMessage('تم تفعيل الحساب بنجاح!'); 
      }
      else if(response.data==='Email or code is invalid or user already activated' && lang.currentLanguage=== 'en'){
        SetActiveMessage('Email or code is invalid or user already activated');
      }
      else if(response.data==='Email or code is invalid or user already activated' && lang.currentLanguage=== 'ar'){
        SetActiveMessage('خطأ في الايميل و/أو رمز التحقق أو هذا الحساب مفعل مسبقاً');
      }
      else{
        SetActiveMessage('Somthing was wrong');  
      }
      const timer = setTimeout(() => {
        window.location.href = config.serverAddress;
      }, 5000);
      return () => clearTimeout(timer);
    }

   
  });

  useEffect(() => {
    ActiveUserAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/Character.json'),
    });
  }, []);

  return (
    <div>
      <Grid h="100vh" bgGradient={'linear(to-r, #192238, #000000'}>
      {isLoading && 
          <Box
          m='auto'
            padding="6"
            boxShadow="lg"
            bgGradient={'linear(to-r, #192238, #000000'}
            disable='flex'
            justifyContent='center'
          >
            <Spinner
            textAlign='center'
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#4FD1C5"
              size="xl"
            />
          </Box>}
        
          <Box width='100%'  m="auto">
            <Box width="80%" m="auto">
              <Text
              
                fontSize="xl"
                fontWeight="bold"
                color="white"
                textAlign="center"
              >
                {activeMessage && activeMessage}
              </Text>
            </Box>
            <Box height="80px"></Box>
            <Box  width="80%" height="400px" m="auto">
              <div className="container" ref={container}>
           
              </div>
            </Box>
          </Box>
      
      </Grid>
    </div>
  );
};
