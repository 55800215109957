import React from 'react';
import { Box, Button, Text, useDisclosure, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Model from '../../Components/Model/HomeModel';
const parentTranslationPath = 'Pricing';
const translationPath = '';

export const PriceCard = ({
  start,
  planName,
  deposit,

  color,
  pic,
  most,
  image,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(parentTranslationPath);
  let token = localStorage.getItem('token');
  const handleOpen = () => {
    if (!token || token === 'null') {
      onOpen();
    } else {
      window.location.href = '/admin/profile';
    }
  };
  return (
    <div>
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Box m="auto" borderRadius="22px" w="400px" h="350px" bgColor="white">
        
        <Box
          // w="95%"
          h="100%"
          display="flex"
          alignItems="center"
          justifyContent={'center'}
        >
        
        
        
          <Image
            objectFit="contain"
            src={image}
            alt={'alt'}
            backgroundSize="contain"
            h={'90%'}
            // width="30%"
            width={'90%'}
            justifyContent="center"
          />
        </Box>
      </Box>
    </div>
  );
};
