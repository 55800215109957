import React from 'react';
import {
  Box,
  Button,
  Text,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Image
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Treading from '../../Layout/Treading/Treading';
import { isMobile } from 'react-device-detect';

// import UPSSlogo01 from '../../assets/images/images/UPSSlogo-01.png';
import IMGTR01 from '../../assets/images/images/IMGTR01.png';

const parentTranslationPath = 'WhatIsAYM';
const translationPath = '';

export const WhatIsAYMBot = ({ WhatIsAYMBotRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);

  const handleOpen = () => {
    if (lang.currentLanguage === 'en') {
      window.open('http://blog.aymbot.com/en/all-about-aymbot/', '_blank');
    } else {
      window.open(
        'https://blog.aymbot.com/ar/category/%d8%ad%d9%88%d9%84-aymbot/',
        '_blank'
      );
    }
  };
  return (
    <div ref={WhatIsAYMBotRef} id="WhatisAYMBot">
      <Grid
        templateColumns={{ md: '1fr', lg: '1.5fr 1.5fr' }}
        templateRows={{ md: '1fr auto', lg: '1fr' }}
        bgColor="white"
      >
        <GridItem margin="auto">
          <SimpleGrid
            spacing={10}
            my="8"
            display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
          >
            <Stack spacing={6} alignItems="center">
              <Box
                w={{
                  // base: '280px',
                  sm: '350px',
                  md: '450px',
                  lg: '500px',
                  xl: '600px',
                  '2xl': '700px',
                }}
                h="auto"
                p="15px"
                // bg="#1e222d"
                borderRadius="20px"
              >
                {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ycDeZBYy074?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                <iframe
                  width={isMobile?"300" :"600" }
                  height="450" 
                  src="https://www.youtube.com/embed/adbs6_UroSw?si=PTy5KnerRnhJpGsW&autoplay=1&mute=1"
                  // src="https://www.youtube.com/embed/Z6Ak7F9FgbQ?si=fbOAk98FLaaoujHg&autoplay=1&mute=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                  allowfullscreen
                ></iframe>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>

        <GridItem margin="auto">
          <SimpleGrid
            display={'center'}
            columns={{ sm: 1, md: 1, xl: 1 }}
            spacing={10}
           
          >
            <Stack spacing={6} alignItems="center" mb={15}>
              <Box w="100%" textAlign="-webkit-center" >
                {/* <Box bgImage={IMGTR01} className='bgImage2'> */}
                <Image
                      objectFit="cover"
                      src={IMGTR01}
                      alt={'alt'}
                      backgroundSize="cover"
                      className='bgImage2'
                      width={'35%'}
                      justifyContent="center"
                      
                    />
                  <Box
                    w={{ base: '100%', lg: '60%' }}
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                      color="#4FD1C5"
                      my="3"
                    >
                      {t(`${translationPath}what-AYM`)}
                    </Text>
                  </Box>
                  <Box
                    w={{ base: '75%', lg: '60%' }}
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                    }}
                    mb="4"
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: 'md', md: 'lg', xl: 'xl' }}
                      color="#4FD1C5"
                      my="3"
                    >
                      {t(`${translationPath}OurVision`)}
                    </Text>
                    <Text
                      className={{
                        base: 'rtl',
                        lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                      fontSize="md"
                      textAlign={{ base: 'center', lg: 'justify' }}
                      color="#757575"
                      w="100%"
                      m="auto"
                    >
                      {t(`${translationPath}OurVisionText`)}
                    </Text>
                  </Box>

                  <Box
                    w={{ base: '75%', lg: '60%' }}
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                    }}
                    mb="4"
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: 'md', md: 'lg', xl: 'xl' }}
                      color="#4FD1C5"
                      my="3"
                    >
                      {t(`${translationPath}OurMission`)}
                    </Text>
                    <Text
                      className={{
                        base: 'rtl',
                        lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                      fontSize="md"
                      textAlign={{ base: 'center', lg: 'justify' }}
                      color="#757575"
                      w="100%"
                      m="auto"
                    >
                      {t(`${translationPath}OurMissionText`)}
                    </Text>
                  </Box>

                  <Box
                    w={{ base: '75%', lg: '60%' }}
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                    }}
                    mb="4"
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: 'md', md: 'lg', xl: 'xl' }}
                      color="#4FD1C5"
                      my="3"
                    >
                      {t(`${translationPath}OurValues`)}
                    </Text>
                    <Text
                      className={{
                        base: 'rtl',
                        lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                      fontSize="md"
                      textAlign={{ base: 'center', lg: 'justify' }}
                      color="#757575"
                      w="100%"
                      m="auto"
                    >
                      {t(`${translationPath}OurValuesText`)}
                    </Text>
                    <UnorderedList>
                      <ListItem>
                        <Text
                          className={{
                            base: 'rtl',
                            lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                          }}
                          fontSize="sm"
                          textAlign={{ base: 'center', lg: 'justify' }}
                          color="#757575"
                          w="100%"
                          m="auto"
                        >
                          {t(`${translationPath}Innovation`)}{' '}
                          {t(`${translationPath}InnovationText`)}
                        </Text>
                      </ListItem>

                      <ListItem>
                        <Text
                          className={{
                            base: 'rtl',
                            lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                          }}
                          fontSize="sm"
                          textAlign={{ base: 'center', lg: 'justify' }}
                          color="#757575"
                          w="100%"
                          m="auto"
                        >
                          {t(`${translationPath}Sustainability`)}{' '}
                          {t(`${translationPath}SustainabilityText`)}
                        </Text>
                      </ListItem>

                      <ListItem>
                        <Text
                          className={{
                            base: 'rtl',
                            lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                          }}
                          fontSize="sm"
                          textAlign={{ base: 'center', lg: 'justify' }}
                          color="#757575"
                          w="100%"
                          m="auto"
                        >
                          {t(`${translationPath}ExcellenceinService`)}{' '}
                          {t(`${translationPath}ExcellenceinServiceText`)}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text
                          className={{
                            base: 'rtl',
                            lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                          }}
                          fontSize="sm"
                          textAlign={{ base: 'center', lg: 'justify' }}
                          color="#757575"
                          w="100%"
                          m="auto"
                        >
                          {t(`${translationPath}PartnershipandCollaboration`)}{' '}
                          {t(
                            `${translationPath}PartnershipandCollaborationText`
                          )}
                        </Text>
                      </ListItem>
                    </UnorderedList>
                  </Box>

                  <Box
                    w={{ base: '75%', lg: '60%' }}
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                    }}
                    mb="4"
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: 'md', md: 'lg', xl: 'xl' }}
                      color="#4FD1C5"
                      my="3"
                    >
                      {t(`${translationPath}OurTeam`)}
                    </Text>
                    <Text
                      className={{
                        base: 'rtl',
                        lg: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                      fontSize="md"
                      textAlign={{ base: 'center', lg: 'justify' }}
                      color="#757575"
                      w="100%"
                      m="auto"
                    >
                      {t(`${translationPath}OurTeamText`)}
                    </Text>
                  </Box>
                {/* </Box> */}
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
