import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {SetGlobalRerender } from './helper';
import SwitchRoute from './Components/Route/SwitchRoute';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'; 
import ScrollToTop from './helper/ScrollToTop';
import GA from 'react-ga';
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from '@react-oauth/google';

import "./assets/aos.css";
import "./assets/animated.css";
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';

const App = () => {
  useEffect(() => {
    GA.initialize('UA-209952826-1');
    GA.pageview(window.location.pathname)
    ReactGA.initialize("G-7Q3Q0VL2GV");
    ReactGA.send(window.location.pathname);
  },[])
  localStorage.setItem('chakra-ui-color-mode','dark')
  // localStorage.setItem('token','eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJjb3Jwc21zIiwic3ViIjoibGFpdGhzYWtldDZAZ21haWwuY29tIiwiaWF0IjoxNjc0MzkyMjY5fQ.4eDxW-MgDBAG78DFK5oA7YvkCKdzonwkzGkrpZFzlVm8Y5JmyuH6FtVL9NkVSwwRocbcJL9YHmreuJb21Bp8Cg');

  const overrides = extendTheme({
    
    styles: {
      global: (props) => ({
        body: {
          fontFamily: 'body',
       
          bgGradient:'linear(to-r,#192238, #000000)'
        },
      }),
    },
  })
  const [render, setRender] = useState(false);
  SetGlobalRerender(setRender, render);

  return (
    <GoogleOAuthProvider clientId="1011127531967-ql5d4juqt25jijdd8h845hca6r6q7jap.apps.googleusercontent.com">

    <Router>
    <ChakraProvider theme={overrides} >
    <ScrollToTop/>
      <SwitchRoute/>
      <div id="recaptcha-container"></div>

      </ChakraProvider>
    </Router>
    </GoogleOAuthProvider>

  );
};

export default App;
