import React from 'react';
import { Modal, ModalContent } from '@chakra-ui/react';

import Login from '../../Layout/HomeLogin/Login';
import StartPage from '../../Layout/StartPage/StartPage';


export const Welcome = ({ onOpen, onClose, isOpen }) => {

  return (
    <div className="" >
      {' '}
      <Modal
      class="modaltest"
        borderRadius={'20px'}
        size='auto'
        isOpen={isOpen}
        onClose={onClose}
        bgColor="transparent"
        isCentered
      >
        <ModalContent 
          width={{base: "450px",  md:"900px", lg: "1200px"}}>
          <StartPage   onClose={onClose} />

        </ModalContent>
      </Modal>
    </div>
  );
};
export default Welcome;
