import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCzzvZUN_FSIZsSOQC_qWFhXaWMiRfSlWo",
  authDomain: "aymbotsm-8b46c.firebaseapp.com",
  projectId: "aymbotsm-8b46c",
  storageBucket: "aymbotsm-8b46c.appspot.com",
  messagingSenderId: "289301742632",
  appId: "1:289301742632:web:a51200ae4caa82429ea11d",
  measurementId: "G-CXQWD6GPG9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);