import React from 'react';
import { Box, Button, Text, Grid, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AffiliateImg from '../../assets/images/images/VINI.png';
import AffiliateImg2 from '../../assets/images/images/Vini-01.png';

import { useHistory } from "react-router-dom";
  
const parentTranslationPath = 'Affiliate';
const translationPath = '';


export const Affiliate = ({ AffiliateRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  let lang = JSON.parse(localStorage.getItem('localization'));
  const history = useHistory();
  const coursesPage = () => {
    history.push("/FAQ")
}

  

  return (
    <div className="Affiliate" ref={AffiliateRef} id="Referrals">
      <Grid
        h={{ base: 'auto', xl: '500px' }}
        bgGradient={'linear(to-r, #192238, #000000'}
     
      >
        <Flex alignItems="center" display="flex" justifyContent="right">
          <Box
            height="100%"
            display={{ base: 'none', lg: 'flex' }}
            w={{ base: '100%', lg: '50%' }}
          >
            <Grid
              backgroundImage={{ base: 'none', lg: AffiliateImg2 }}
              backgroundPosition="initial"
              backgroundRepeat="no-repeat"
              opacity="0.8"
              backgroundSize={{ base: '100% 100%' }}
              width="80%"
              height="100%"
            ></Grid>
          </Box>

          <Box w={{ base: '100%', lg: '50%' }} my="10">
            <Stack spacing={6} m="auto" alignItems="center">
              <Text
                color="white"
                fontWeight="bold"
                textAlign={{ base: 'center' }}
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                {t(`${translationPath}Affiliate`)}
              </Text>
              <Text
                className={lang.currentLanguage === 'en' ?'':"rtl"}
                color="#757575"
                w="60%"
                textAlign={{ base: 'center' }}
                fontSize="md"
                my="10"
              >
                {t(`${translationPath}p-1`)}
              </Text>
             
              <Box m="auto">
                <Button
                  isDisabled={false}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  my="10px"
                  fontSize="16px"
                  bg="#4FD1C5"
                  color="white"
                  size="lg"
                  w="180px"
                  onClick={coursesPage}
                >
                  {t(`${translationPath}coming-soon`)}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Grid>
    
    </div>
  );
};
