import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationMap from "../ContactUsSection/LocationMap";
import { isMobile } from 'react-device-detect';

const parentTranslationPath = 'FAQ';
const translationPath = '';

export const ContactUs = ({ ContactUsRef }) => {
  const history = useHistory();
  const { t } = useTranslation(parentTranslationPath);

  const handleContactUs = () => {
    history.push(`/ContactUs`);
  };

  return (
    <div ref={ContactUsRef} id='ContactUs'>
      <Grid
     
        h={{ base: 'auto' }}
        bgColor="#E5E5E5"
        templateColumns={{ base: 'repeat(1, 2fr)' }}
        className={"Wrapper"}
      >
        {/* <GridItem my="10">
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Box  w="100%" textAlign="center" my="0">
              <Text
                color="#4FD1C5"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                fontWeight="bold"
              >
                {t(`${translationPath}contact-us`)}
              </Text>

              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                py="4"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
              >
                {t(`${translationPath}P-1`)}
              </Text>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
              >
                {t(`${translationPath}P-2`)}
              </Text>
            </Box>
            <Box w="100%" mt="6" display="flex" justifyContent="center">
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                whiteSpace="normal"
                _hover="none"
                fontSize="15px"
                bg="#27314C"
                color="white"
                size="md"
                w="180px"
                onClick={handleContactUs}
              >
                {t(`${translationPath}contact-btn`)}
              </Button>
            </Box>
          </SimpleGrid>
        </GridItem> */}

        <div className="contact-area pt-100 pb-100">
        <div className="container">
          <div className={isMobile?"contact-map mb-10":"contact-map mb-10 "} >
            <LocationMap latitude="31.88630920171674" longitude="35.987350309253166" />
          </div>
          <Box className={isMobile?"HeaderInfo2Phone":"HeaderInfo2"}>
            {/* <div className="custom-row-2"> */}

            <div className="col-lg-4 col-md-5">
              <div className="contact-info-wrap">
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="contact-info-dec">
                    <p>+962 6505 9494 </p>
                    <p>+962 77 995 5590</p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-globe" />
                  </div>
                  <div className="contact-info-dec">
                    <p>
                      {/* <a href="mailto:urname@email.com">samer@upss-jo.com</a> */}
                    </p>
                    <p>
                      <a href="//urwebsitenaem.com">info@upss-jo.com</a>
                    </p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="contact-info-dec">
                    <p>Address JORDAN-AMMAN, </p>
                    <p>street, ALRajeeb.</p>
                  </div>
                </div>
                <div className="contact-social text-center">
                  {/* <h3>Follow Us</h3> */}
            
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-7">
                <div className="contact-form">
                  <div className="contact-title mb-30">
                    <h2> {t(`${translationPath}Get In Touch`)}</h2> 
                  </div>
                  <form className="contact-form-style">
                    <div className="row">
                      <div className="col-lg-6">
                        <input name="name" placeholder="Name*" type="text" />
                      </div>
                      <div className="col-lg-6">
                        <input name="email" placeholder="Email*" type="email" />
                      </div>
                      <div className="col-lg-12">
                        <input
                          name="subject"
                          placeholder="Subject*"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          name="message"
                          placeholder="Your Message*"
                          defaultValue={""}
                        />
                        <button className="submit" type="submit">
                          SEND
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="form-messege" />
                </div>
              </div>
          
         
       



            {/* </div> */}
          </Box>
        </div>
      </div>
     
      </Grid>
    </div>
  );
};






// const Wrapper = styled.section`
//   width: 100%;
// `;

// const HeaderInfo2 = styled.div`
//   display: flex;
//   padding: 30px 0 30px;
//   justify-content: space-between;
// `;


// const RowTest = styled.div`{
//     display: flex;
//     flex-wrap: wrap;
//     margin-right: -15px;
//     margin-left: -15px;

// }`;