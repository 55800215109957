import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// import 'swiper/pagination/pagination.min.css';
// import 'swiper/navigation/navigation.min.css';
import black from '../../assets/images/images/Black.gif';
import './StyleSwiper.scss';
import { isMobile } from 'react-device-detect';

import { PriceCard } from '../../ReusableComponent/PriceCard/PriceCard';
import { useMediaQuery } from '@chakra-ui/media-query';
// import IMGQ3 from './../../assets/images/whyaym/QQ3.jpg';
import IMGOFFERs from './../../assets/images/whyaym/OffersEN.jpg';

import IMGA1 from './../../assets/images/whyaym/A1.png';
import IMGA2 from './../../assets/images/whyaym/A2.png';
import IMGA3 from './../../assets/images/whyaym/A3.png';
import IMGA4 from './../../assets/images/whyaym/A4.png';
import IMGA5 from './../../assets/images/whyaym/A5.png';



// SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
import {
  Navigation,
  EffectCoverflow,
} from 'swiper';

export const App = () => {
  const [isLargerThan280] = useMediaQuery('(min-width: 300px)');
  const [isLargerThan299] = useMediaQuery('(max-width: 345px)');

  return (
    <div className="">
      <Swiper
        className={
          !isMobile?'swiperBorder3':
          isLargerThan280 && isLargerThan299?
          'swiperBorder4':
          'swiperBorder2'
        }
        freeMode={true}
        navigation={true}
        effect={'coverflow'}
        centeredSlides={true}
        slidesPerView={'auto'}
        loop={true}
        coverflowEffect={{
          rotate: 15,
          stretch: 10,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Navigation]}
      >
        <SwiperSlide>
          <PriceCard
            most={'Most Popular'}
            price={'7.5% of initial balance / year'}
            image={IMGA1}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Platinum'}
            image={IMGA2}
           
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Black'}
            image={IMGA3}
           
          />
        </SwiperSlide>
        <SwiperSlide>
        <PriceCard
            planName={'Black'}
            image={IMGA4}
           
          />
        </SwiperSlide>
        <SwiperSlide>
        <PriceCard
            planName={'Black'}
            image={IMGA5}
           
          />
        </SwiperSlide>
      
       
      </Swiper>
    </div>
  );
};
