import React from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  GridItem,
  Grid,
  Flex,
  Link,
  Button,
} from '@chakra-ui/react';

import instagram from '../../assets/images/images/instagram.png';
import facebook from '../../assets/images/images/facebook.png';
import linkedin from '../../assets/images/images/linkedin.png';
import email from '../../assets/images/images/email.png';
import youtube from '../../assets/images/images/youtube.png';
import { useTranslation } from 'react-i18next';
import pay from '../../assets/images/images/pay.png';
import { useHistory } from 'react-router-dom';

const parentTranslationPath = 'Footer';
const translationPath = '';

export const Footer = () => {
  const history = useHistory();
  let lang = JSON.parse(localStorage.getItem('localization'));

  const { t } = useTranslation(parentTranslationPath);

  const handleContactUs = () => {
    history.push(`/ContactUs`);
  };
  return (
    <div>
      <Flex h={{ base: 'auto' }} bgGradient={'linear(to-r, #192238, #000000'}>
        <Box
          display="flex"
          justifyContent={lang.currentLanguage === 'ar' ? 'right' : 'left'}
          w="100%"
        >
          <Box
            my="8"
            w={{ base: '100%', md: '100%', lg: '50%', xl: '30%' }}
            className="footer-img"
            display="flex"
            justifyContent="center"
          >
            <Text
              mx="2"
              fontWeight="bold"
              color="white"
              fontSize={{ base: 'xl', sm: '2xl' }}
            >
              {/* {t(`${translationPath}AYMBot®`)} */} 
              UPSS®
            </Text>
            <Text
              color="white"
              mt={{ base: '2', sm: '2' }}
              fontSize={{ base: 'sm', sm: 'md' }}
            >
              {/* {t(`${translationPath}solution`)} */} Universal Protection for Smart System
            </Text>
          </Box>
        </Box>
      </Flex>

      <Grid
        w={{ base: '100%' }}
        h={{ base: 'auto', lg: '300px' }}
        bgGradient={'linear(to-r, #192238, #000000)'}
        templateColumns={{ base: 'repeat(1, 2fr)', lg: 'repeat(3, 2fr)' }}
      >
        <GridItem alignSelf="center">
          <SimpleGrid columns={{ base: 1, lg: 3 }} display="flex">
            <Box
              display="block"
              m={{ base: 'auto' }}
              textAlign={{ base: 'center', lg: lang.currentLanguage === 'en' ?'left' :'right' }}
            >
             
              <Text mb="4" color="white" mt="4"></Text>
              <Link
                // href={lang.currentLanguage==='en' ?"https://blog.aymbot.com/en/category/release-notes/":"https://blog.aymbot.com/ar/category/%d9%85%d9%84%d8%a7%d8%ad%d8%b8%d8%a7%d8%aa-%d8%a7%d9%84%d8%a5%d8%b5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/"}
                target="_blank"
                mb="4"
                color="white"
                mt="4"
              >
              {/* {t(`${translationPath}Release-History`)} */}
              E-Commerce Upss

               
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
              <Link
                // href={lang.currentLanguage==="en"?"http://blog.aymbot.com/en/":"http://blog.aymbot.com/ar/"}
                target="_blank"
                color="white"
                mt="4"
              >
              {t(`${translationPath}Learn-More`)}
                
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
              <Link
                // href={lang.currentLanguage==='en'?"https://blog.aymbot.com/en/category/faqs/":"https://blog.aymbot.com/ar/category/%d8%a3%d8%b3%d8%a6%d9%84%d8%a9-%d8%b4%d8%a7%d8%a6%d8%b9%d8%a9/"}
                target="_blank"
                color="white"
                mt="4"
              >
              {t(`${translationPath}FAQ`)}
                
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
            
              <Text mb="4" color="white" mt="4"></Text>
            </Box>
          </SimpleGrid>
        </GridItem>
        <GridItem alignSelf="center">
          <SimpleGrid columns={{ base: 1, lg: 3 }} display="flex">
            <Box
              m={{ base: 'auto' }}
              textAlign={{ base: 'center', lg: lang.currentLanguage === 'en' ?'left' :'right' }}
            >
              <Link
                // href={"http://blog.aymbot.com/en/aymbot-privacy-policy/"}
                target="_blank"
                color="white"
                mt="4"
              >
              {t(`${translationPath}Privacy-Policy`)}
                
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
              <Link
                // href="http://blog.aymbot.com/en/aymbot-cookie-policy/"
                target="_blank"
                mb="4"
                color="white"
                mt="4"
              >
              {t(`${translationPath}Cookie-Policy`)}
                
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
              <Link
                // href="http://blog.aymbot.com/en/aymbot-service-subscription-agreement-terms-and-conditions/"
                target="_blank"
                mb="4"
                color="white"
                mt="4"
              >
              {t(`${translationPath}Terms-Service`)}
                
              </Link>
              <Text mb="4" color="white" mt="4"></Text>
            </Box>
          </SimpleGrid>
        </GridItem>
        <GridItem alignSelf="center">
          <SimpleGrid display="flex">
            <Box m={{ base: 'auto' }} display="bolck" textAlign="center">
              <Text width="100%" mb="2" fontSize="xl" color="white">
                
                {t(`${translationPath}Payment-methods`)}
              </Text>

              <Box
                className="media-pay"
                w="100%"
                height="auto"
                display="flex"
                m="auto"
              >
                {/* <img  src={pay} alt={'alt'} /> */} 
                <Text>{t(`${translationPath}cash`)}</Text>
              </Box>
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>
      <Grid bgGradient={'linear(to-r, #192238, #000000)'}>
        <GridItem display="flex" width="100%">
          <SimpleGrid justifyContent="center" margin="auto" display="flex">
            <Box
              className="media"
              w="auto"
              h="30px"
              m={{ base: '8px', lg: '10px' }}
            >
              <img
                onClick={() => {
                  window.open(
                    // ' https://www.instagram.com/AYM_holdings/',
                    '_blank'
                  );
                }}
                src={instagram}
                alt="instagram"
                width="20px"
                height="40px"
              />
            </Box>
            <Box
              className="media"
              w="auto"
              h="30px"
              m={{ base: '6px', lg: '8px' }}
            >
              <img
                onClick={() => {
                  window.open(
                    // 'https://www.facebook.com/AYMholdings/',
                    '_blank'
                  );
                }}
                src={facebook}
                alt="facebook"
              />
            </Box>
            <Box
              className="media"
              w="auto"
              h="30px"
              m={{ base: '6px', lg: '8px' }}
            >
              <img
                onClick={() => {
                  window.open(
                    // 'https://www.linkedin.com/company/aymholdings/',
                    '_blank'
                  );
                }}
                src={linkedin}
                alt="linkedin"
              />
            </Box>

            <Box
              className="media"
              w="auto"
              h="30px"
              m={{ base: '6px', lg: '8px' }}
            >
              <img
                onClick={() => {
                  window.open(
                    // 'https://www.youtube.com/c/AYMHoldings',
                    '_blank'
                  );
                }}
                src={youtube}
                alt="youtube"
              />
            </Box>

            <Box
              className="media"
              w="auto"
              h="30px"
              m={{ base: '6px', lg: '8px' }}
            >
              <img
                onClick={() => {
                  window.open('', '_blank');
                }}
                src={email}
                alt="email"
              />
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>

      <Grid
        bgGradient={'linear(to-r, #192238, #000000)'}
        h={{ base: 'auto', lg: '50px' }}
        bgColor="#151C2F"
      >
        <GridItem>
          <SimpleGrid>
            <Box m="auto" mt="1">
              <Text fontSize="13px" color="white" textAlign="center">
              {/* {t(`${translationPath}R1`)} */}
              
               
              </Text>
              <Text fontSize="13px" textAlign="center" color="white">
              {t(`${translationPath}R2`)}
              </Text>
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
