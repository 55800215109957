import { HttpServices } from '../../helper/HttpMethod.Helper';
// import config from '../../assets/Configration/Configration.json';

export const GetPricing = async ( initBalance ) => {
  const result = await HttpServices.get(
    `https://aymbot.com/core/user/rest/AYMBot/pricing`,
  
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'initBalance': `${initBalance}`,
      },
    }
  );
  return result;
};
