import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './assets/Style/Master.scss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Middleware } from './helper';
import '../src/fonts/Panton/Panton-Bold.ttf';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
  <Middleware />
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
