import React from 'react';
import {
  Box,
  Button,
  Text,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  useDisclosure,
  Center,
  Image,
  Flex
} from '@chakra-ui/react';
// import step3 from '../../assets/images/images/step3.png';
import step1 from '../../assets/images/images/step-1.png';
import step2 from '../../assets/images/images/step-2.png';
import step3 from '../../assets/images/images/step-3.png';

import IMG9 from './../../assets/images/whyaym/IMG-9.jpg';
import IMG2 from './../../assets/images/whyaym/IMG-2.png';
import IMG7 from './../../assets/images/whyaym/IMG-7.jpg';
import IMG8 from './../../assets/images/whyaym/IMG-8.png';
import IMG11 from './../../assets/images/whyaym/IMG-11.jpg';
import IMG14 from './../../assets/images/whyaym/IMG-14.png';
import IMG17 from './../../assets/images/whyaym/IMG-17.png';
import IM4 from './../../assets/images/whyaym/4.jpg';
import IM7 from './../../assets/images/whyaym/7.jpg';
import IM8 from './../../assets/images/whyaym/8.jpg';
import IM9 from './../../assets/images/whyaym/9.jpg';
import IM11 from './../../assets/images/whyaym/11.jpg';
import IM13 from './../../assets/images/whyaym/13.jpg';


import { useMediaQuery } from '@chakra-ui/media-query';

import { useTranslation } from 'react-i18next';
import { Model } from './../Model/HomeModel';
const parentTranslationPath = 'GetStarted';
const translationPath = '';

export const GetStarted = ({ GetStartedRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let token = localStorage.getItem('token');
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const [isSmallScreenFold] = useMediaQuery(
    '(min-width: 332px)' && '(max-width: 400px)'
  );
  const images = [
    {
      name: 'Intelligent',
      img: IM4,
      img2: IM4,
      title: 'Intelligent-title',
    },
    {
      name: 'Bleeding-Edge',
      img: IM7,
      img2: IM7,
      title: 'Bleeding-Edge-title',
    },
    
    {
      name: 'Safe-Flexible',
      img: IM8,
      img2: IM8,
      title: 'Safe-Flexible=title',
    },
    {
      name: 'Relentless',
      img: IM9,
      img2: IM9,
      title: 'Relentless-title',
    },

    {
      name: 'Data-Driven',
      img: IM11,
      img2: IM11,
      title: 'Data-Driven-title',
    },
    {
      name: 'Fully-Automated',
      img: IM13,
      img2: IM13,
      title: 'Fully-Automated-title',
    },
  ];

  const handleOpen = () => {
    if (!token || token === 'null') {
      onOpen();
    } else {
      window.location.href = '/admin/profile';
    }
  };
  return (
    <div ref={GetStartedRef} id="Getstarted">
      <Box mt="8" mb={8} bgColor="#FFF" justifyItems="center">
        <Center>
          <Box
            display="flex"
            justifyItems="center"
            alignItems="center"
            alignSelf="center"
          >
            <Text color="#4FD1C5" fontSize="40px" mt="8" fontWeight="bold">
              {' '}
              {t(`${translationPath}why-aym`)}
            </Text>
          </Box>
        </Center>

        <SimpleGrid
          display="flex"
          mt="8"
          bgColor="#FFF"
          justifyItems="center"
          justifyContent={'center'}
        >
          <div>
            <Box>
              <Box width="100%" m="auto">
                {isSmallScreen || isSmallScreenFold ? (
                  <Grid
                    bgColor="#FFF"
                    justifyItems="center"
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(2, 1fr)"
                    gap={2}
                    mt="8"
                  >
                    {images.map((item, i) => (
                      <GridItem key={i}>
                        <SimpleGrid minChildWidth="120px">
                          <Box width="80%" m="auto">
                            <Flex
                              display="block"
                              textAlign={{ base: 'center' }}
                            >
                              <Box display="flex" justifyContent="center">
                                {' '}
                                <Image
                                  objectFit="contain"
                                  src={item.img}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  h="80px"
                                  // width="30%"
                                  width={['100%', '50%']}
                                />
                              </Box>

                              <Text
                                fontSize="md"
                                color="#4FD1C5"
                                mt="2"
                                display="flex"
                                justifyContent="center"
                                fontWeight="bold"
                              >
                                {t(`${translationPath}${item.name}`)}
                              </Text>
                            </Flex>
                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              width="100%"
                              color="#FFF"
                              mb="10"
                              fontSize="18px"
                            >
                              <div
                                style={{ width: '90%', display: 'inline-flex' }}
                              >
                                <Text
                                  fontSize="sm"
                                  m={{ sm: 'auto' }}
                                  textAlign={{ base: 'center' }}
                                  w={{ sm: '60%', md: '70%', lg: '100%' }}
                                >
                                  {t(`${translationPath}${item.title}`)}
                                </Text>
                              </div>
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  // <Grid
                  //   bgColor="#FFF"
                  //   justifyItems="center"
                  //   templateRows="repeat(2, 1fr)"
                  //   templateColumns="repeat(3, 1fr)"
                  //   gap={2}
                  //   mt="8"
                  // >
                  //   {images.map((item, i) => (
                  //     <GridItem key={i}>
                  //       <SimpleGrid minChildWidth="120px">
                  //         <Box width="100%" m="auto">
                  //           <Flex
                  //             display="block"
                  //             textAlign={{ base: 'center' }}
                  //           >
                  //             <Box display="flex" justifyContent="center">
                  //               {' '}

                  //               <ImageToggleOnMouseOver
                  //                 primaryImg={item.img}
                  //                 secondaryImg={item.img2}
                  //                 alt=""
                  //               />
                  //             </Box>

                  //             <Text
                  //               fontSize="md"
                  //               color="#4FD1C5"
                  //               mt="2"
                  //               display="flex"
                  //               justifyContent="center"
                  //               fontWeight="bold"
                  //             >
                  //               {t(`${translationPath}${item.name}`)}
                  //             </Text>
                  //           </Flex>
                  //           <Box
                  //             display="flex"
                  //             justifyContent="center"
                  //             textAlign="center"
                  //             width="100%"
                  //             color="#757575"
                  //             mb="10"
                  //             fontSize="18px"
                  //           >
                  //             <div
                  //               style={{ width: '90%', display: 'inline-flex' }}
                  //             >
                  //               <Text
                  //                 fontSize="sm"
                  //                 m={{ sm: 'auto' }}
                  //                 textAlign={{ base: 'center' }}
                  //                 w={{ sm: '60%', md: '70%', lg: '100%' }}
                  //               >
                  //                 {t(`${translationPath}${item.title}`)}
                  //               </Text>
                  //             </div>
                  //           </Box>
                  //         </Box>
                  //       </SimpleGrid>
                  //     </GridItem>
                  //   ))}
                  // </Grid>
                  <Box className="containerforhover">
                    {images.map((item, i) => (
                      <Box className="boxss">
                        <Image
                          objectFit="cover"
                          // src={item.img}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="400px"
                          src={item.img}
                          // width="30%"
                          width={'100%'}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </div>
        </SimpleGrid>
      </Box>
    </div>
  );
};
